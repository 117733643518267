import {SVGProps} from 'react';

type Props = SVGProps<any>;

export default function Menu(props: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 359 359"
            style={{
                fill: 'currentColor',
            }}
            xmlSpace="preserve"
            {...props}
        >
            <path d="M96 0H13C7.5 0 3 4.5 3 10v83c0 5.5 4.5 10 10 10h83c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10zm-9 84H23V20h64v64zM221 0h-83c-5.5 0-10 4.5-10 10v83c0 5.5 4.5 10 10 10h83c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10zm-9 84h-64V20h64v64zM96 126H13c-5.5 0-10 4.5-10 10v83c0 5.5 4.5 10 10 10h83c5.5 0 10-4.5 10-10v-83c0-5.5-4.5-10-10-10zm-9 83H23v-63h64v63zM221 126h-83c-5.5 0-10 4.5-10 10v83c0 5.5 4.5 10 10 10h83c5.5 0 10-4.5 10-10v-83c0-5.5-4.5-10-10-10zm-9 83h-64v-63h64v63zM346 0h-83c-5.5 0-10 4.5-10 10v83c0 5.5 4.5 10 10 10h83c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10zm-9 84h-64V20h64v64zM346 126h-83c-5.5 0-10 4.5-10 10v83c0 5.5 4.5 10 10 10h83c5.5 0 10-4.5 10-10v-83c0-5.5-4.5-10-10-10zm-9 83h-64v-63h64v63zM96 256H13c-5.5 0-10 4.5-10 10v83c0 5.5 4.5 10 10 10h83c5.5 0 10-4.5 10-10v-83c0-5.5-4.5-10-10-10zm-9 84H23v-64h64v64zM221 256h-83c-5.5 0-10 4.5-10 10v83c0 5.5 4.5 10 10 10h83c5.5 0 10-4.5 10-10v-83c0-5.5-4.5-10-10-10zm-9 84h-64v-64h64v64zM346 256h-83c-5.5 0-10 4.5-10 10v83c0 5.5 4.5 10 10 10h83c5.5 0 10-4.5 10-10v-83c0-5.5-4.5-10-10-10zm-9 84h-64v-64h64v64z" />
        </svg>
    );
}
